<img class="swap-image" [ngSrc]="frontUrl" [alt]="frontAlt"
     width="auto" height="auto"
     (load)="frontLoad()" (error)="frontError()" />

@if (backUrl) {
  <img class="swap-image" [ngSrc]="backUrl" [alt]="backAlt"
       width="auto" height="auto"
       (load)="backLoad()" (error)="backError()" />
}

@if (errorFront && errorBack) {
  <div class="error-container">
    <mat-icon svgIcon="icons:error"></mat-icon>
    Wystąpił błąd przy pobieraniu obrazka
  </div>
}

@if (!loaded && !errorFront && !errorBack) {
  <mat-spinner diameter="70"></mat-spinner>
}
