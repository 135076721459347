<div class="container box-shadow">
  @if (pdfUrl) {
    <ul>
      <li class="box-shadow">
        <app-icon-button label="pobierz pdf" icon="pdf" target="_blank" class="no-border large" [href]="pdfUrl"></app-icon-button>
      </li>
      <li class="box-shadow">
        <app-icon-button label="pobierz epub" icon="epub" target="_blank" class="no-border large" [href]="epubUrl"></app-icon-button>
      </li>
      <li class="box-shadow">
        <app-icon-button label="pobierz mobi" icon="mobi" target="_blank" class="no-border large" [href]="mobiUrl"></app-icon-button>
      </li>
      <mat-icon class="download-icon" svgIcon="icons:download"></mat-icon>
    </ul>
  }

  @if (googleUrl) {
    <div class="google-drive">
      <mat-icon svgIcon="icons:google-drive"></mat-icon>
      <span class="text">
      Pliki dostępne również na naszym</span>
      <a class="text" [href]="googleUrl">dysku google</a>.
    </div>
  }

  <p class="extra"><ng-content></ng-content></p>
</div>
