import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgOptimizedImage } from '@angular/common';

import { GoogleUrlPipe } from '../../pipes/google-url.pipe';
import { MediaDirectivesModule } from '../../directives/media/media-directives.module';

@Component({
  selector: 'app-swap-images',
  templateUrl: './swap-images.component.html',
  styleUrls: ['./swap-images.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    GoogleUrlPipe,
    MatProgressSpinnerModule,
    MediaDirectivesModule,
    MatIcon,
    NgOptimizedImage
  ],
})
export class SwapImagesComponent implements OnInit {
  @Input({ required: true }) protected readonly frontUrl: string = '';
  @Input({ required: true }) protected readonly frontAlt: string = '';

  @Input() protected readonly backUrl: string = '';
  @Input() protected readonly backAlt: string = '';

  @HostBinding('class.loaded') protected loaded = false;
  @HostBinding('class.error') protected error = false;
  @HostBinding('class.error-front') protected errorFront = false;
  @HostBinding('class.error-back') protected errorBack = false;
  @HostBinding('class.only-front') protected onlyFront = false;
  @HostBinding('class.only-back') protected onlyBack = false;

  protected frontLoaded = false;
  protected backLoaded = false;

  ngOnInit(): void {
    this.onlyFront = !this.backUrl;
  }

  protected frontLoad(): void {
    this.frontLoaded = true;
    this.loaded = this.frontLoaded && (this.backLoaded || !this.backLoaded);
  }

  protected frontError(): void {
    this.errorFront = true;
    this.error = this.errorFront && this.errorBack;
    this.onlyBack = true;
    this.loaded = this.backLoaded;
  }

  protected backLoad(): void {
    this.backLoaded = true;
    this.loaded = this.frontLoaded && this.backLoaded;
  }

  protected backError(): void {
    this.errorBack = true;
    this.error = this.errorFront && this.errorBack;
    this.onlyFront = true;
    this.loaded = this.frontLoaded;
  }
}
