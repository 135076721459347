import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { IconButtonComponent } from '../icon-button/icon-button.component';

@Component({
  selector: 'app-book-download',
  templateUrl: './book-download.component.html',
  styleUrls: ['./book-download.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IconButtonComponent,
    MatIconModule,
  ],
})
export class BookDownloadComponent {
  @Input() protected readonly pdfUrl: string;
  @Input() protected readonly epubUrl: string;
  @Input() protected readonly mobiUrl: string;
  @Input() protected readonly googleUrl: string;
}
